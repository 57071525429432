import { Image } from "@shopify/polaris";
import { renderTypeFormApp } from "../utils/renderFunctions";
import { downArrow, upArrow } from "../assets";
import { useEffect } from "react";
import axios from "axios";
import { isValidPhone } from "../utils/constants";

const STYLIST_NUMBER_LIST=["+14026949091","+15127738011","+14026948417"];
const STYLIST_EMAIL_LIST=["lana@lookingglasslifestyle.com","lauren@lookingglasslifestyle.com","jordan@lookingglasslifestyle.com"];


const QuestionForm = ({
  typeFormData,
  setTypeFormData,
  btn,
  errorPhone,
  setErrorPhone,
  validPhoneFlag,
  setValidPhoneFlag,
  upDisable,
  downDisable,
  setUpDisable,
  setDownDisable,
  setBtn,
  openChat,
  OpenBookVideoChat,
  setOpenVideo,
  setOpenChat,
  setOpenBookVideoChat,
  setDisplayThankYou,
  displayThankYou,
  setOpenQR,
  validDetails,
  setValidDetails,
}) => {
  const sendMessageUser = async (phone) => {
    const payload = {
      number: phone,
    };
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/message-user`, payload);
    } catch (error) {
      console.log("error=", error);
    }
  };
  const sendMessageStylist = async (contact) => {
    const payload = {
      number: contact,
    };
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/message-stylist`, payload);
    } catch (error) {
      console.log("error=", error);
    }
  };
  const handleSessionData = async () => {
    const result = await axios.post(`${process.env.REACT_APP_BASE_URL}/session-requirements`, { ...typeFormData });
    setTypeFormData({
      ...typeFormData,
      phone: "",
      event: null,
      feel: null,
      city: "",
      date: `${new Date().getMonth() + 1}/${new Date().getDate()}/${new Date().getFullYear()}`,
      optional_outfit: [],
      outfit: [],
    });

    if (openChat) {
      sendMessageUser(result?.data?.phone);
      STYLIST_NUMBER_LIST.forEach((el)=>{
        sendMessageStylist(el);
      })
      setDisplayThankYou({ ...displayThankYou, forChat: true });
      setOpenChat(false);
      setOpenQR(true);
    }

    if (OpenBookVideoChat) {
      setOpenBookVideoChat(false);
      setOpenVideo(true);
      setOpenQR(false);
      setOpenChat(false);
    }
  };

  const handleCardDisplay = (id) => {
    setBtn(id);
  };

  const handleUpBtn = () => {
    if (btn >= 0 && btn < 8) {
      setUpDisable(false);
      setDownDisable(false);
      setBtn(btn + 1);
    } else {
      setUpDisable(true);
    }
  };

  const handleDownBtn = () => {
    if (btn > 0 && btn <= 8) {
      setUpDisable(false);
      setDownDisable(false);
      setBtn(btn - 1);
    } else {
      setDownDisable(true);
    }
  };

  const sendEmailToStylist = async (email) => {
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/email-stylist`, {
        customerId: typeFormData.customerId,
        phone: typeFormData.phone,
        event: typeFormData.event,
        feel: typeFormData.feel,
        city: typeFormData.city,
        name: typeFormData.name,
        email: email,
        outfit: typeFormData.outfit,
        date: typeFormData.date,
        type: openChat ? "chat" : "video",
      });
    } catch (e) {
      console.error(e);
    }
  };

  const sendEmailToUser = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/email-user`, {
        name: typeFormData.name,
        email: typeFormData.email,
        type: openChat ? "chat" : "video",
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleTypeFormSubmit = () => {
    if (typeFormData.phone === "") {
      setErrorPhone(true);
    } else if (!isValidPhone(typeFormData.phone)) {
      setValidPhoneFlag(true);
    } else if (
      typeFormData.city === "" ||
      typeFormData.email === "" ||
      typeFormData.event === "" ||
      typeFormData.feel === "" ||
      typeFormData.name === ""
    ) {
      setValidDetails(true);
    } else {
      STYLIST_EMAIL_LIST.forEach((el)=>{
        sendEmailToStylist(el);
      })
      sendEmailToUser();
      handleSessionData();
    }
  };
  useEffect(() => {
    handleCardDisplay(btn);
  }, [btn]);

  return (
    <div className="question-wrapper">
      {renderTypeFormApp(
        handleCardDisplay,
        typeFormData,
        setTypeFormData,
        btn,
        handleTypeFormSubmit,
        errorPhone,
        setErrorPhone,
        validPhoneFlag,
        setValidPhoneFlag,
        validDetails,
        setValidDetails
      )}
      <div className="question-updown-btn">
        <button onClick={handleUpBtn} disabled={upDisable}>
          <Image source={upArrow} alt="Arrow-Up" />
        </button>
        <button onClick={handleDownBtn} disabled={downDisable}>
          <Image source={downArrow} alt="Arrow-Down" />
        </button>
      </div>
    </div>
  );
};

export default QuestionForm;
